/* eslint-disable react/prop-types */
import React from 'react'

const BlogContainer = ({ children }) => {
  return (
    <div className="mx-auto container max-w-3xl px-2 sm:px-4">{children}</div>
  )
}

export default BlogContainer
