/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import BlogContainer from '../components/layout/blogContainer'
import Heading1 from '../components/elements/heading1'

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const postTitle = post.frontmatter.title
  const postFeaturedImage =
    post.frontmatter.featuredImage.childImageSharp.resize
  const postFeatureText = post.frontmatter.featuredText
  // const { previous, next } = this.props.pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={postTitle}
        description={postFeatureText || post.excerpt}
        image={postFeaturedImage}
        pathname={location.pathname}
      />
      <BlogContainer>
        <div className="mt-24 md:mt-32 lg:mt-48 pb-20">
          <div className="px-8">
            <Heading1>{postTitle}</Heading1>
            <hr className="mt-4 mb-8"></hr>
          </div>
          <MDXRenderer>{post.body}</MDXRenderer>
          {/* <hr /> */}
          {/* <Bio /> */}
          {/* <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul> */}
        </div>
      </BlogContainer>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      slug
      excerpt(pruneLength: 160)
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        featureText
      }
      body
    }
  }
`
